/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";

import createCache from "@emotion/cache";
import { CacheProvider, Global } from "@emotion/react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

import {
  COUPONS,
  CUSTOMER,
  EVERYTHING_ELSE,
  EXIT,
  FINISH,
  FULFILLER,
  PIZZAS,
  ROUTE_TITLES,
} from "routes";

import {
  selectShowFutureTimeError,
  setShowFutureTimeError,
} from "rtk_redux/slices/futureTimedOrderSlice";

import { SERVICE_METHOD } from "constants/order";
import useTealium from "modules/tealium";

import Backdrop from "components/Backdrop";
import Header from "components/Header";
import LDWrapper from "components/LDWrapper";
import Menu from "components/Menu";
import NewCustomerPage from "components/NewCustomerPage";
import Overlay from "components/Overlay";
import Receipt from "components/Receipt";
import Sidebar from "components/Sidebar";
import Toaster from "components/Toaster";
import CouponsContainer from "containers/Coupons";
import CustomerContainer from "containers/Customer";
import EverythingElseContainer from "containers/EverythingElse";
import ExitContainer from "containers/Exit";
import FinishContainer from "containers/Finish";
import FulfillerContainer from "containers/Fulfiller";
import PizzasContainer from "containers/Pizzas";

import {
  baseStyles,
  couponsStyles,
  desktopStyles,
  landscapeStyles,
  mobileStyles,
  ordersStyles,
  pizzasStyles,
  rearrangedNavbarStyles,
  tabletStyles,
} from "styles";

const App = ({
  addressLine1 = "",
  addressLine2 = "",
  streetAddress,
  agentId = "",
  coupons = [],
  clearMessage,
  clearSession,
  currentOrderCoupons = {},
  currentOrderProducts = {},
  currentOrderSavings = 0,
  currentServiceMethod = SERVICE_METHOD.CARRYOUT,
  currentOrderTotal = 0,
  customerName = "",
  customerPhone = "",
  deleteOrderCoupon,
  deleteOrderProduct,
  deliveryInstructions,
  getMenu,
  goToCoupons,
  goToCustomer,
  goToEverythingElse,
  goToExit,
  goToFinish,
  goToFulfiller,
  goToHome,
  goToPizzas,
  i18n = { changeLanguage: () => {} },
  maps,
  market,
  marketCode,
  marketConfigs,
  newExperienceFeatures,
  orderStarted = false,
  overlayMessages = {},
  priceValidateOrder,
  receiptSummary = {},
  resetActiveOrderProduct,
  setActiveOrderProduct,
  setLanguage,
  setOrderProduct,
  startOrder,
  storeId = "",
  storeName = "",
  storePhone = "",
  storeRegion = "",
  storeStreet = "",
  subTab = "profile",
  t = () => {},
  tab = CUSTOMER,
  toasterMessages = {},
  validOrderTiming = false,
  isNavigationDisabled = false,
}) => {
  const dispatch = useDispatch();
  const showFutureTimeError = useSelector(selectShowFutureTimeError);
  const [showSidebar, setShowSidebar] = useState(false);

  const { NEW_EXPERIENCE_FEATURES, TEALIUM_PROFILE } = marketConfigs;
  const { rearrangedNavbar } = NEW_EXPERIENCE_FEATURES;

  useTealium({ profile: TEALIUM_PROFILE });

  useEffect(() => {
    startOrder();
  }, [startOrder]);

  const toggleSidebar = () => setShowSidebar((s) => !s);

  const validateRoute = (routeAction) => {
    if (orderStarted)
      return validOrderTiming
        ? routeAction()
        : dispatch(setShowFutureTimeError(true));

    return routeAction();
  };

  const { newCustomerPage: shouldUseNewCustomerPage, showDefaultStoreWarning } =
    newExperienceFeatures;

  const isDefaultTab = !subTab || subTab === "profile";
  const hasCoupons = Object.keys(coupons).length > 0;
  const hasSidebar = tab !== CUSTOMER || isDefaultTab;
  const isDefaultStore = market.nationalStoreId === storeId;

  const cache = createCache({ key: "order-entry-ui" });
  cache.compat = true;

  //TODO: uncomment when coupon wizard functionality is available
  // const specials = (
  //     <Sidebar showSidebar={showSidebar} toggleSidebar={toggleSidebar}>
  //         <Specials coupons={coupons} marketCode={marketCode} t={t} />
  //     </Sidebar>
  // );

  useEffect(() => {
    const defaultLanguage = market.languageCodes.split(",")[0];

    if (defaultLanguage) {
      i18n.changeLanguage(defaultLanguage);
      setLanguage(defaultLanguage);
      getMenu({
        language: defaultLanguage,
        storeId,
      });
    }
  }, []);

  const receipt = (
    <Sidebar showSidebar={showSidebar} toggleSidebar={toggleSidebar}>
      <Receipt
        currentServiceMethod={currentServiceMethod}
        customerName={customerName}
        customerPhone={customerPhone}
        deleteOrderCoupon={deleteOrderCoupon}
        deleteOrderProduct={deleteOrderProduct}
        deliveryAddressLine1={addressLine1}
        deliveryAddressLine2={addressLine2}
        deliveryStreetAddress={streetAddress}
        goToCustomer={goToCustomer}
        goToEverythingElse={goToEverythingElse}
        goToFulfiller={goToFulfiller}
        goToPizzas={goToPizzas}
        marketCode={marketCode}
        marketConfigs={marketConfigs}
        orderCoupons={currentOrderCoupons}
        orderProducts={currentOrderProducts}
        priceValidateOrder={priceValidateOrder}
        receiptSummary={receiptSummary}
        resetActiveOrderProduct={resetActiveOrderProduct}
        savings={currentOrderSavings}
        setActiveOrderProduct={setActiveOrderProduct}
        setOrderProduct={setOrderProduct}
        storeName={storeName}
        storeId={storeId}
        storePhone={storePhone}
        storeRegion={storeRegion}
        storeStreet={storeStreet}
        t={t}
        total={currentOrderTotal}
        shouldUseNewCustomerPage={shouldUseNewCustomerPage}
        deliveryInstructions={deliveryInstructions}
      />
    </Sidebar>
  );

  let result;

  switch (tab) {
    case PIZZAS:
      result = <PizzasContainer sidebar={receipt} />;
      break;
    case EVERYTHING_ELSE:
      result = <EverythingElseContainer sidebar={receipt} />;
      break;
    case COUPONS:
      result = <CouponsContainer sidebar={receipt} />;
      break;
    case FINISH:
      result = <FinishContainer sidebar={receipt} />;
      break;
    case FULFILLER:
      result = (
        <FulfillerContainer
          sidebar={receipt}
          goToEverythingElse={goToEverythingElse}
          goToPizzas={goToPizzas}
        />
      );
      break;
    case EXIT:
      result = <ExitContainer sidebar={receipt} />;
      break;
    case CUSTOMER:
    default:
      result = shouldUseNewCustomerPage ? (
        <NewCustomerPage sidebar={receipt} t={t} />
      ) : (
        <CustomerContainer
          clearFutureTimeError={() => dispatch(setShowFutureTimeError(false))}
          maps={maps}
          setFutureTimeError={() => dispatch(setShowFutureTimeError(true))}
          showFutureTimeError={showFutureTimeError}
          sidebar={null}
          //TODO: uncomment when coupon wizard functionality is available
          // sidebar={isDefaultTab && hasCoupons ? specials : null}
        />
      );
      break;
  }

  return (
    <LDWrapper>
      <CacheProvider value={cache}>
        <Global
          styles={[
            baseStyles,
            mobileStyles,
            landscapeStyles,
            tabletStyles,
            desktopStyles,
            pizzasStyles,
            ordersStyles,
            couponsStyles,
            rearrangedNavbar && rearrangedNavbarStyles,
          ]}
        />
        <Helmet>
          <title>{ROUTE_TITLES.HOME}</title>
        </Helmet>
        <div className="app">
          <Backdrop visible={showSidebar} />

          <Header
            agentId={agentId}
            clearSession={clearSession}
            getMenu={getMenu}
            goToHome={goToHome}
            hasSidebar={hasSidebar}
            i18n={i18n}
            isDefaultStore={showDefaultStoreWarning && isDefaultStore}
            languageCodes={market.languageCodes}
            setLanguage={setLanguage}
            showSidebar={showSidebar}
            storeId={storeId}
            t={t}
            toggleSidebar={toggleSidebar}
          />

          <div className="app__body">
            {result}

            <Menu
              t={t}
              tab={tab}
              goToExit={goToExit}
              goToCustomer={goToCustomer}
              goToPizzas={goToPizzas}
              goToEverythingElse={goToEverythingElse}
              goToCoupons={goToCoupons}
              goToFinish={goToFinish}
              isNavigationDisabled={isNavigationDisabled}
              validateRoute={validateRoute}
              hasCoupons={hasCoupons}
            />

            <div className="toaster">
              {Object.entries(toasterMessages)
                .reverse()
                .map(
                  ([id, { interpolation, message, status, title }], index) => (
                    <Toaster
                      key={id}
                      messageNumber={index + 1}
                      status={status}
                      title={title}
                      unrender={() => clearMessage(id)}
                    >
                      {t(message, interpolation)}
                    </Toaster>
                  )
                )}
            </div>

            {Object.entries(overlayMessages)
              .reverse()
              .map(
                ([
                  id,
                  {
                    interpolation,
                    message,
                    title,
                    showExpandedOrderConfirmationMessage,
                    expandedOrderConfirmationMessageData,
                  },
                ]) => (
                  <Overlay
                    confirmText={t("shared:action.ok")}
                    key={id}
                    title={t(title)}
                    unrender={() => clearMessage(id)}
                    message={t(message, interpolation)}
                    showExpandedOrderConfirmationMessage={
                      showExpandedOrderConfirmationMessage
                    }
                    expandedOrderConfirmationMessageData={
                      expandedOrderConfirmationMessageData
                    }
                    t={t}
                  />
                )
              )}
          </div>
        </div>
      </CacheProvider>
    </LDWrapper>
  );
};

App.propTypes = {
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  streetAddress: PropTypes.string,
  agentId: PropTypes.string,
  clearMessage: PropTypes.func.isRequired,
  clearSession: PropTypes.func.isRequired,
  coupons: PropTypes.objectOf(
    PropTypes.shape({
      couponCode: PropTypes.string,
      couponDescription: PropTypes.string,
      couponName: PropTypes.string,
      couponPrice: PropTypes.string,
    })
  ),
  currentOrderCoupons: PropTypes.objectOf(PropTypes.object),
  currentOrderProducts: PropTypes.objectOf(PropTypes.object),
  currentOrderSavings: PropTypes.number,
  currentOrderTotal: PropTypes.number,
  currentServiceMethod: PropTypes.string,
  customerName: PropTypes.string,
  customerPhone: PropTypes.string,
  deleteOrderCoupon: PropTypes.func.isRequired,
  deleteOrderProduct: PropTypes.func.isRequired,
  getMenu: PropTypes.func.isRequired,
  goToCoupons: PropTypes.func.isRequired,
  goToCustomer: PropTypes.func.isRequired,
  goToEverythingElse: PropTypes.func.isRequired,
  goToExit: PropTypes.func.isRequired,
  goToFinish: PropTypes.func.isRequired,
  goToFulfiller: PropTypes.func.isRequired,
  goToHome: PropTypes.func.isRequired,
  goToPizzas: PropTypes.func.isRequired,
  i18n: PropTypes.shape({ changeLanguage: PropTypes.func }),
  maps: PropTypes.objectOf(PropTypes.any).isRequired,
  market: PropTypes.objectOf(PropTypes.object),
  marketCode: PropTypes.string.isRequired,
  marketConfigs: PropTypes.shape({
    SINGLE_LINE_ITEM_MAX_QTY: PropTypes.number,
    SINGLE_LINE_ITEM_MIN_QTY: PropTypes.number,
    NEW_EXPERIENCE_FEATURES: PropTypes.object,
    TEALIUM_PROFILE: PropTypes.string,
    PRIMARY_LANGUAGE: PropTypes.string,
  }).isRequired,
  orderStarted: PropTypes.bool,
  overlayMessages: PropTypes.objectOf(PropTypes.object),
  priceValidateOrder: PropTypes.func.isRequired,
  receiptSummary: PropTypes.objectOf(PropTypes.object),
  resetActiveOrderProduct: PropTypes.func.isRequired,
  setActiveOrderProduct: PropTypes.func.isRequired,
  setLanguage: PropTypes.func.isRequired,
  setOrderProduct: PropTypes.func.isRequired,
  startOrder: PropTypes.func.isRequired,
  storeId: PropTypes.string,
  storeName: PropTypes.string,
  storePhone: PropTypes.string,
  storeRegion: PropTypes.string,
  storeStreet: PropTypes.string,
  subTab: PropTypes.string,
  t: PropTypes.func,
  tab: PropTypes.string,
  toasterMessages: PropTypes.objectOf(PropTypes.object),
  validOrderTiming: PropTypes.bool,
  isNavigationDisabled: PropTypes.bool,
  newExperienceFeatures: PropTypes.object,
  deliveryInstructions: PropTypes.string,
};

export default App;
