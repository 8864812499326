export const COUNTRY_CODES = Object.freeze({
  COLOMBIA: "CO",
  DOMINICAN_REPUBLIC: "DO",
  KENYA: "KE",
  MALAYSIA: "MY",
  MEXICO: "MX",
  NIGERIA: "NG",
  SAUDI_ARABIA: "SA",
  SOUTH_AFRICA: "ZA",
  UNITED_STATES: "US",
});

export const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";
export const DEFAULT_TIME_FORMAT = "H:MM";
export const DEFAULT_LANGUAGE_CODES = "en,es";

export const MARKET_CENTER = Object.freeze({
  COLOMBIA: {
    lat: 4.5709,
    lng: -74.2973,
  },
  DOMINICAN_REPUBLIC: {
    lat: 18.4935944,
    lng: -69.9377209,
  },
  MALAYSIA: {
    lat: 3.140853,
    lng: 101.693207,
  },
  SAUDI_ARABIA: {
    lat: 25.8517475,
    lng: 43.52223129999993,
  },
  MEXICO: {
    lat: 23.634501,
    lng: -102.552784,
  },
  NIGERIA: {
    lat: 9.081999,
    lng: 8.675277,
  },
  KENYA: {
    lat: -1.2921,
    lng: 36.8219,
  },
  SOUTH_AFRICA: {
    lat: -33.92698,
    lng: 18.419422800000007,
  },
});
