import { combineEpics, ofType } from "redux-observable";
import { filter, map, mergeMap, pluck, take } from "rxjs/operators";

import { customerRoute } from "routes";

import { SET_AGENT_HAS_ORDER, getAgent } from "ducks/agent";
import { SET_MARKET, getMarkets } from "ducks/market";
import { getDefaultLanguageFromLanguageCodes } from "ducks/market/selectors";
import { SET_MENU, getMenu } from "ducks/menu";
import { rncSetReceiptType } from "ducks/rnc";
import { SET_ROOT } from "ducks/root";
import { SET_STORE, getStore } from "ducks/store";
import { getLinkFromRelationship } from "selectors/related";

import { resetCustomerPage } from "rtk_redux/slices/customerPageSlice";
import { resetFinishState } from "rtk_redux/slices/finishSlice";
import { resetPriceOrderState } from "rtk_redux/slices/priceOrderSlice";

import deepExtend from "modules/deepExtend";

import * as locale from "assets/locale";
import * as localeMY from "assets/locale-MY";
import * as localeNG from "assets/locale-NG";
import * as localeSA from "assets/locale-SA";
import * as localeUS from "assets/locale-US";

const SCOPE = "order-entry/configuration/";

export const GET_LANGUAGES = `${SCOPE}GET_LANGUAGES`;
export const SET_LANGUAGES = `${SCOPE}SET_LANGUAGES`;
export const SET_LANGUAGE = `${SCOPE}SET_LANGUAGE`;
export const SET_CONFIGURED = `${SCOPE}SET_CONFIGURED`;
export const RESET_STATE = `${SCOPE}RESET_STATE`;

export function getLanguages() {
  return {
    type: GET_LANGUAGES,
  };
}

export function setLanguages({ languages = {}, languagesSet = false } = {}) {
  return {
    type: SET_LANGUAGES,
    languages,
    languagesSet,
  };
}

export function setLanguage({ language = "en" }) {
  return {
    type: SET_LANGUAGE,
    language,
  };
}

export function setConfigured({
  agent = false,
  configured = false,
  market = false,
  menu = false,
  root = false,
  store = false,
} = {}) {
  return {
    type: SET_CONFIGURED,
    agent,
    configured,
    market,
    menu,
    root,
    store,
  };
}

export function resetState() {
  return {
    type: RESET_STATE,
  };
}

export const initialState = {
  agent: false,
  configured: false,
  currentLanguage: "en",
  languages: {
    en: {},
  },
  languagesSet: false,
  market: false,
  menu: false,
  rnc: {},
  root: false,
  store: false,
};

const isConfigured = (configured) => configured;

export default function reducer(state = initialState, action = {}) {
  const [agent, market, menu, root, store] = Array(5).fill(false);
  const configurationProps = { agent, market, menu, root, store };

  switch (action.type) {
    case SET_LANGUAGE:
      return deepExtend({ currentLanguage: "" }, state, {
        currentLanguage: action.language || "en",
      });
    case SET_LANGUAGES:
      return deepExtend({ languages: {} }, state, {
        languages: action.languages,
        languagesSet: action.languagesSet,
      });
    case SET_CONFIGURED:
      Object.keys(configurationProps).forEach((prop) => {
        configurationProps[prop] = state[prop] || action[prop];
      });
      return Object.assign({}, state, configurationProps, {
        configured: Object.values(configurationProps).every(isConfigured),
      });
    default:
      return state;
  }
}

const nationalStoreProps = {
  setAsActive: true,
  selected: false,
};

export const getLanguagesEpic = (action$) =>
  action$.pipe(
    ofType(GET_LANGUAGES),
    map(() =>
      setLanguages({
        languages: locale,
        languagesSet: true,
      })
    )
  );

export const setRootEpic = (action$) =>
  action$.pipe(
    ofType(SET_ROOT),
    mergeMap((root) => [
      setConfigured({
        root: true,
      }),
      getLanguages(),
      getMarkets({ url: getLinkFromRelationship("markets")(root) }),
    ])
  );

export const setMarketEpic = (action$) =>
  action$.pipe(
    ofType(SET_MARKET),
    mergeMap(
      ({
        marketCode,
        relationships: {
          nationalStore: {
            links: {
              related: { href: url },
            },
          },
        },
      }) => {
        let languages;
        switch (marketCode) {
          case "MY": {
            languages = localeMY;
            break;
          }
          case "NG": {
            languages = localeNG;
            break;
          }
          case "SA": {
            languages = localeSA;
            break;
          }
          case "US": {
            languages = localeUS;
            break;
          }
          default:
            languages = locale;
        }
        const actions = [
          setConfigured({
            market: true,
          }),
          getStore(Object.assign({ url }, nationalStoreProps)),
          setLanguages({
            languages,
            languagesSet: true,
          }),
        ];

        return actions;
      }
    )
  );

export const setStoreEpic = (action$, { getState }) =>
  action$.pipe(
    ofType(SET_STORE),
    filter(({ active }) => active),
    mergeMap(({ language, selected, storeId }) => {
      const state = getState();
      const defaultLanguage = getDefaultLanguageFromLanguageCodes(state);

      return [
        setConfigured({
          store: true,
        }),
        getMenu({
          defaultMenu: !selected,
          language: language || defaultLanguage,
          storeId,
        }),
      ];
    })
  );

export const setMenuEpic = (action$, { getState }) =>
  action$.pipe(
    ofType(SET_MENU),
    take(1),
    map(getState),
    pluck("root"),
    mergeMap((root) => [
      setConfigured({
        menu: true,
      }),
      getAgent({ url: getLinkFromRelationship("agent")(root) }),
    ])
  );

export const setAgentHasOrderEpic = (action$) =>
  action$.pipe(
    ofType(SET_AGENT_HAS_ORDER),
    take(1),
    map(() =>
      setConfigured({
        agent: true,
      })
    )
  );

export const resetStateEpic = (action$, { getState }) =>
  action$.pipe(
    ofType(RESET_STATE),
    map(getState),
    pluck("market"),
    map((market) => getLinkFromRelationship("nationalStore")(market)),
    mergeMap((url) => [
      rncSetReceiptType(""),
      getStore(Object.assign({ url }, nationalStoreProps)),
      customerRoute(),
      resetFinishState(),
      resetCustomerPage(),
      resetPriceOrderState(),
    ])
  );

export const epics = combineEpics(
  getLanguagesEpic,
  setMarketEpic,
  setMenuEpic,
  setRootEpic,
  setStoreEpic,
  setAgentHasOrderEpic,
  resetStateEpic
);
