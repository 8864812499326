import { createSelector } from "reselect";

import { PROFILE_FIELDS } from "constants/customer";
import deepExtend from "modules/deepExtend";

export const getMarket = ({ market = {} } = {}) => market;

export const getMarketCode = createSelector(
  [getMarket],
  ({ marketCode = "" }) => marketCode
);

export const getDefaultLanguage = createSelector(
  [getMarket],
  ({ defaultLanguage = "en" }) => defaultLanguage
);

export const getDefaultLanguageFromLanguageCodes = createSelector(
  [getMarket],
  ({ languageCodes = "en,es" }) => languageCodes.split(",")[0]
);

export const getMarketHeader = createSelector(
  [getMarket],
  ({ marketHeader = "" }) => marketHeader
);

export const getMarketName = createSelector(
  [getMarket],
  ({ marketName = "" }) => marketName
);

export const getMarketConfigs = createSelector(
  [getMarket],
  ({ marketConfigs = {} }) => marketConfigs
);

export const getMarketProfileFields = createSelector(
  [getMarketConfigs],
  ({ PROFILE_FIELDS_OVERRIDES = {} }) =>
    deepExtend({}, PROFILE_FIELDS, PROFILE_FIELDS_OVERRIDES)
);

export const getIsFilterDeliveryOnlyEnabled = createSelector(
  [getMarketConfigs],
  ({ FILTER_DELIVER_ONLY_STORES }) => FILTER_DELIVER_ONLY_STORES
);

export const getIsSuppressCoordinateDeliveryInstructionsEnabled =
  createSelector(
    [getMarketConfigs],
    ({ SUPPRESS_COORDINATES_DELIVERY_INSTRUCTIONS }) =>
      SUPPRESS_COORDINATES_DELIVERY_INSTRUCTIONS
  );

export const getMarketWaterfallUpsells = createSelector(
  [getMarketConfigs],
  ({ WATERFALL_UPSELLS = [] }) => WATERFALL_UPSELLS
);

export const getManualCouponEntryTab = createSelector(
  [getMarketConfigs],
  ({ MANUAL_COUPON_ENTRY_TAB = {} }) => MANUAL_COUPON_ENTRY_TAB
);

export const getNewExperienceFeature = createSelector(
  [getMarketConfigs],
  ({ NEW_EXPERIENCE_FEATURES = {} }) => NEW_EXPERIENCE_FEATURES
);

export const getNewExperienceFeatureOptions = createSelector(
  [getMarketConfigs],
  ({ NEW_EXPERIENCE_FEATURE_OPTIONS = {} }) => NEW_EXPERIENCE_FEATURE_OPTIONS
);

export default getMarketCode;
