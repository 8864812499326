import React, { Fragment } from "react";

import PropTypes from "prop-types";

import { DEFAULT_LANGUAGE_CODES } from "constants/market";

import Nav from "components/Nav";
import NavItem from "components/NavItem";

const Header = ({
  agentId,
  clearSession,
  getMenu,
  i18n,
  languageCodes,
  setLanguage,
  isDefaultStore,
  showNavItems,
  storeId,
  t,
  ...props
}) => {
  const createDropdownOptions = () => {
    const languageCodeToArray = languageCodes.split(",");

    return languageCodeToArray.map((languageCode) => ({
      action: () => {
        i18n.changeLanguage(languageCode);
        setLanguage(languageCode);
        getMenu({
          language: languageCode,
          storeId,
        });
      },
      languageCode,
      title: t(`application:language.${languageCode}`),
    }));
  };

  return (
    <Nav title={t("application:name")} className="app__header" {...props}>
      {showNavItems && (
        <Fragment>
          {isDefaultStore && (
            <NavItem
              icon="store"
              id="btn-default-store"
              quidBase="secondary-nav-default-store"
              text={`${t("application:default_store", { store: storeId })}`}
              className="bg--dominos-red"
            />
          )}

          <NavItem
            icon="person"
            id="display-agent"
            quidBase="secondary-nav-agent-id"
            text={agentId}
          />

          <NavItem
            icon="exit_to_app"
            id="btn-logout"
            quidBase="secondary-nav-logout"
            text={t("shared:logout")}
            type="button"
            onClick={clearSession}
          />

          <NavItem
            icon="volume_up"
            id="btn-hold-call"
            quidBase="secondary-nav-hold-call"
            text={t("application:hold_call")}
            type="button"
          />

          <NavItem
            dropdown={createDropdownOptions()}
            icon="language"
            id="btn-language"
            quidBase="secondary-nav-language"
            text={t("application:language.title")}
            type="button"
          />

          {process.env.NODE_ENV === "development" && (
            <NavItem
              icon="build"
              id="version-number"
              quidBase="secondary-nav-version"
              text={process.env.REACT_APP_VERSION}
            />
          )}
        </Fragment>
      )}
    </Nav>
  );
};

Header.propTypes = {
  agentId: PropTypes.string,
  clearSession: PropTypes.func,
  getMenu: PropTypes.func,
  i18n: PropTypes.shape({
    changeLanguage: PropTypes.func,
  }),
  isDefaultStore: PropTypes.bool,
  languageCodes: PropTypes.string,
  setLanguage: PropTypes.func,
  showNavItems: PropTypes.bool,
  storeId: PropTypes.string,
  t: PropTypes.func,
};

Header.defaultProps = {
  agentId: "",
  clearSession: () => {},
  getMenu: () => {},
  i18n: {
    changeLanguage: () => {},
  },
  languageCodes: DEFAULT_LANGUAGE_CODES,
  setLanguage: () => {},
  showNavItems: true,
  storeId: "",
  t: () => {},
};

export default Header;
